import {
  Image,
  ImageContainer,
  ItemContainer,
  ItemQuantify,
  ItemTitle,
  Price,
  Product,
  Quantity,
} from "./styles";

import InputSpinner from "react-native-input-spinner";

import { ActionColor, colorWhite } from "../../utils/colors";
import { formatCurrency } from "../../utils/formatNumber";
import { normalizeLimit } from "../../utils/normalizeLimit";
import { BasketItem } from "../../models/basketItem";
import { useCallback } from "react";

interface ProductItemProps {
  id: string;
  maxQuantity: number;
  image: string;
  name: string;
  price: number;
  quantity: number;
  items: BasketItem[];
  cartItems: BasketItem[];
  updateItems: (items: BasketItem[]) => void;
  updateCartItems: (items: BasketItem[]) => void;
}

export default function ProductItem({
  id,
  maxQuantity,
  image,
  name,
  price,
  quantity,
  items,
  updateItems,
  cartItems,
  updateCartItems,
}: ProductItemProps) {
  const updateList = useCallback(
    (
      list: BasketItem[],
      updateFunction: (items: BasketItem[]) => void,
      newItem: BasketItem
    ) => {
      const updatedList = list.some((item) => item.id === newItem.id)
        ? list.map((item) =>
            item.id === newItem.id
              ? { ...item, quantity: newItem.quantity }
              : item
          )
        : [...list, newItem];

      updateFunction(updatedList);
    },
    []
  );
  return (
    <Product key={id}>
      <ImageContainer>
        <Quantity>{maxQuantity}</Quantity>
        <Image source={{ uri: image }} />
      </ImageContainer>
      <ItemTitle>{name}</ItemTitle>
      <ItemContainer>
        <ItemQuantify>
          <InputSpinner
            max={maxQuantity}
            min={0}
            step={1}
            colorMax={colorWhite}
            colorMin={colorWhite}
            rounded={false}
            value={quantity}
            onChange={(getQuantity: number) => {
              const newItem = {
                id,
                name,
                price,
                image,
                quantity: getQuantity,
              };
              updateList(items, updateItems, newItem);
              updateList(cartItems, updateCartItems, newItem);
            }}
            inputStyle={{ width: normalizeLimit(50) }}
            colorRight={colorWhite}
            colorLeft={colorWhite}
            color={colorWhite}
            colorPress={colorWhite}
            textColor="#333333"
            fontSize={normalizeLimit(24)}
            buttonFontSize={normalizeLimit(40)}
            buttonTextColor={ActionColor}
            buttonStyle={{ backgroundColor: "transparent" }}
          />
        </ItemQuantify>
        <Price>{formatCurrency(price / 100)}</Price>
      </ItemContainer>
    </Product>
  );
}
