import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { RefreshControl } from "react-native";
import { Button } from "react-native-elements";
import Loading from "../../components/Loading";
import MachineSelectedView from "../../components/MachineSelectedView";
import ProductItem from "../../components/ProductItem";
import Warning from "../../components/Warning";
import { AlertOS } from "../../hooks/AlertOS";
import { NO_ITENS } from "../../image";
import { BasketItem } from "../../models/basketItem";
import { MachineIdentification } from "../../models/machine";
import { ProductBasket } from "../../models/product";
import { HomeRoutesStackTypes } from "../../routes/home.routes";
import api from "../../services/api";
import { formatCurrency } from "../../utils/formatNumber";
import {
  Container,
  InfoPurchase,
  ProductContainer,
  ProductList,
  ProductsAvaliable,
  PurchaseTotal,
} from "./styles";

interface MachineBasketProps {
  machineId: string;
  machine: MachineIdentification;
}

const MachineBasket: React.FC = () => {
  const navigation = useNavigation<HomeRoutesStackTypes>();
  const route =
    useRoute<RouteProp<Record<string, MachineBasketProps>, string>>();
  const { machineId } = route.params;
  const [machine, setMachine] = useState<MachineIdentification>(
    route.params.machine
  );
  const [loading, setLoading] = useState(false);
  const [cartProducts, setCartProducts] = useState<BasketItem[]>([]);
  const [machineProducts, setMachineProducts] = useState<BasketItem[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const total = getTotal();

  function getTotal() {
    return machineProducts.reduce((acc, cur) => {
      acc += cur.price * cur.quantity;
      return acc;
    }, 0);
  }

  async function getMachine() {
    try {
      setLoading(true);
      const response = await api.get(`/machine/${machineId}`);
      const { machines } = response.data;
      let machineProducts = machines?.products.map((item) => {
        const { product } = item;
        return {
          id: product.id,
          image: product.image,
          name: product.name,
          price: product.price,
          maxQuantity: item.quantity,
          quantity: 0,
        };
      });
      setMachineProducts(machineProducts);
      setMachine(machines);
      setLoading(false);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }

  async function saveCartItems(items: BasketItem[]) {
    try {
      setLoading(true);
      const params = {
        machine_id: machine.id,
        items,
      };
      api.post("/cart", { ...params });
      setLoading(false);
    } catch (err) {
      console.log("ERROR: ", err);
      setLoading(false);
    }
  }

  function handlePayment() {
    if (!total) {
      AlertOS({
        title: "Selecione um produto",
        message:
          "Não existem produtos no carrinho. Selecione um produto para prosseguir",
        toastType: "info",
      });
      return;
    }
    let items = machineProducts.filter(
      (machineProduct) => machineProduct.quantity != 0
    );
    navigation.navigate("Payment", {
      machine,
      total,
      items,
    });
  }

  function renderItem({ item }: { item: ProductBasket }) {
    return (
      <ProductItem
        {...item}
        items={machineProducts}
        updateItems={setMachineProducts}
        cartItems={cartProducts}
        updateCartItems={setCartProducts}
      />
    );
  }

  useEffect(() => {
    saveCartItems(cartProducts);
  }, [cartProducts]);

  useEffect(() => {
    getMachine();
  }, []);

  return loading ? (
    <Loading text="Carregando..." />
  ) : (
    <Container>
      <MachineSelectedView machine={machine} />
      {machine.products.length == 0 && !refreshing && (
        <Warning
          imageSource={NO_ITENS}
          title="Não há produtos nesta máquina"
        ></Warning>
      )}
      {refreshing ? (
        <Loading text={"Buscando produtos..."} />
      ) : (
        <ProductContainer
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={getMachine}
              enabled={true}
            />
          }
        >
          <ProductList
            numColumns={2}
            data={machineProducts}
            keyExtractor={(item: ProductBasket) => item.id}
            renderItem={renderItem}
          />
        </ProductContainer>
      )}
      <InfoPurchase>
        <ProductsAvaliable>Itens a venda</ProductsAvaliable>
        <PurchaseTotal>Total: {formatCurrency(total / 100)}</PurchaseTotal>
      </InfoPurchase>
      <Button
        title="Escolher forma de Pagamento"
        loading={loading}
        onPress={handlePayment}
      />
    </Container>
  );
};

export default MachineBasket;
